<template>
  <dt :class="config.size_value">
    <img
      v-if="!config.form.active || config.fconfig.readonly"
      v-bind:src="config.form.formData[config.fconfig.image_cropped]"
      :class="{
        'c-avatar-img': config.fconfig.circle,
        'img-fluid': !config.fconfig.circle,
      }"
    />
    <p
      v-if="
        !config.form.active &&
        !config.form.formData[config.fconfig.image_cropped]
      "
    >
      Nessuna immagine caricata
    </p>
    <Cropper
      ref="cropper"
      v-if="config.form.active && image"
      :stencil-component="
        config.fconfig.circle
          ? $options.components.CircleStencil
          : $options.components.RectangleStencil
      "
      class="cropper"
      :src="image"
      :stencil-props="{
        aspectRatio: config.fconfig.ratio,
      }"
      :default-position="
        this.image_cropping_vals.length == 4 ? defaultPosition : undefined
      "
      :default-size="
        this.image_cropping_vals.length == 4 ? defaultSize : undefined
      "
      @change="changeCrop"
    ></Cropper>
    <CButtonGroup v-if="config.form.active">
      <CButton
        v-if="image"
        variant="outline"
        color="primary"
        size="sm"
        @click.prevent="$refs.file.click()"
      >
        <CIcon name="cil-chevron-right" /> cambia
      </CButton>

      <CButton
        v-else
        variant="outline"
        color="primary"
        size="sm"
        @click.prevent="$refs.file.click()"
      >
        <CIcon name="cil-chevron-right" /> carica
      </CButton>

      <CButton
        v-if="image"
        variant="outline"
        color="primary"
        size="sm"
        @click.prevent="removeImage"
      >
        <CIcon name="cil-chevron-right" /> elimina
      </CButton>

      <input
        type="file"
        ref="file"
        @change="uploadImage($event)"
        accept="image/*"
        class="d-none"
      />
    </CButtonGroup>
    <small v-if="config.form.active" class="form-text text-muted w-100">{{
      config.fconfig.description
    }}</small>
    <!-- <CSpinner v-if="!options" color="secondary" size="sm" />
    <span v-else-if="!config.form.active || config.fconfig.readonly">
      {{ SelectedLabel }}
    </span>
    <CVSelect
      v-else-if="config.form.active && !config.fconfig.readonly"
 
      v-model="config.form.formData[config.fname]"
      :options="options"
      :is-valid="config.is_valid(config.fname)"
      :invalid-feedback="config.invalid_feedback(config.fname)"
      :description="config.fconfig.description"
    /> -->
  </dt>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";

export default {
  props: ["config"],
  data() {
    return {
      image: this.config.form.formData[this.config.fconfig.image],
      image_cropping:
        this.config.form.formData[this.config.fconfig.image_cropping],
    };
  },
  components: {
    Cropper,
  },
  methods: {
    uploadImage(event) {
      // console.log("load image");
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.image = e.target.result;
          this.image_cropping = "";
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },
    removeImage() {
      console.log("remove image");
      this.image = "";
      this.image_cropping = "";
    },
    changeCrop({ coordinates }) {
      // console.log("move event", coordinates);
      // console.log(coordinates, canvas);
      this.image_cropping = [
        coordinates.left,
        coordinates.top,
        coordinates.left + coordinates.width,
        coordinates.top + coordinates.height,
      ].join(",");
      this.config.form.formData[this.config.fconfig.image_cropping] =
        this.image_cropping;
    },
    defaultPosition() {
      if (this.image_cropping_vals.length == 4) {
        return {
          left: this.image_cropping_vals[0],
          top: this.image_cropping_vals[1],
        };
      }
      return "";
    },
    defaultSize() {
      if (this.image_cropping_vals.length == 4) {
        return {
          width: this.image_cropping_vals[2] - this.image_cropping_vals[0],
          height: this.image_cropping_vals[3] - this.image_cropping_vals[1],
        };
      }
      return "";
    },
  },
  // created() {
  //   this.config.fconfig.options.then((opts) => {
  //     this.options = opts;
  //   });
  // },
  watch: {
    image() {
      this.config.form.formData[this.config.fconfig.image] = this.image;
    },
    // "config.fconfig.options"() {
    // //   console.log(this.config.fconfig.options);
    // //  this.options = null
    //   this.config.fconfig.options.then((opts) => {
    //     this.options = opts;
    //   });
    // },
  },
  computed: {
    image_cropping_vals() {
      return this.image_cropping.split(",");
    },
  },
};
</script>
