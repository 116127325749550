var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dt',{class:_vm.config.size_value},[(!_vm.config.form.active || _vm.config.fconfig.readonly)?_c('img',{class:{
      'c-avatar-img': _vm.config.fconfig.circle,
      'img-fluid': !_vm.config.fconfig.circle,
    },attrs:{"src":_vm.config.form.formData[_vm.config.fconfig.image_cropped]}}):_vm._e(),(
      !_vm.config.form.active &&
      !_vm.config.form.formData[_vm.config.fconfig.image_cropped]
    )?_c('p',[_vm._v(" Nessuna immagine caricata ")]):_vm._e(),(_vm.config.form.active && _vm.image)?_c('Cropper',{ref:"cropper",staticClass:"cropper",attrs:{"stencil-component":_vm.config.fconfig.circle
        ? _vm.$options.components.CircleStencil
        : _vm.$options.components.RectangleStencil,"src":_vm.image,"stencil-props":{
      aspectRatio: _vm.config.fconfig.ratio,
    },"default-position":this.image_cropping_vals.length == 4 ? _vm.defaultPosition : undefined,"default-size":this.image_cropping_vals.length == 4 ? _vm.defaultSize : undefined},on:{"change":_vm.changeCrop}}):_vm._e(),(_vm.config.form.active)?_c('CButtonGroup',[(_vm.image)?_c('CButton',{attrs:{"variant":"outline","color":"primary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.file.click()}}},[_c('CIcon',{attrs:{"name":"cil-chevron-right"}}),_vm._v(" cambia ")],1):_c('CButton',{attrs:{"variant":"outline","color":"primary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.file.click()}}},[_c('CIcon',{attrs:{"name":"cil-chevron-right"}}),_vm._v(" carica ")],1),(_vm.image)?_c('CButton',{attrs:{"variant":"outline","color":"primary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.removeImage.apply(null, arguments)}}},[_c('CIcon',{attrs:{"name":"cil-chevron-right"}}),_vm._v(" elimina ")],1):_vm._e(),_c('input',{ref:"file",staticClass:"d-none",attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.uploadImage($event)}}})],1):_vm._e(),(_vm.config.form.active)?_c('small',{staticClass:"form-text text-muted w-100"},[_vm._v(_vm._s(_vm.config.fconfig.description))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }